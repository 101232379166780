@import "../../styles/dynamic/media_queries.scss";
@import "../../styles/dynamic/colors.scss";
@import "../../styles/dynamic/all.scss";

.confirm_container {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  @include breakpoint(md) {
    align-items: center;
  }

  .body_container {
    background: $color_white;
    width: 100%;
    max-height: 90vh;
    min-height: 195px;
    opacity: 1;
    z-index: 999;
    padding: 24px;
    color: #2c2c2c;
    // font-family: "noto-sans";
    font-size: 14px;
    line-height: 18px;
    bottom: -1px;
    border-radius: 12px 12px 0px 0px;
    position: absolute;
    @include breakpoint(md) {
      position: relative;
      width: 432px;
      border-radius: 12px;
    }

    &.txtTemplateContainer {
      @include breakpoint(md) {
        position: absolute;
        width: 432px;
        border-radius: 0px;
        right: 0px;
        top: 0px;
        height: 100vh;
        max-height: 100vh;
        padding: 70px 16px 16px 16px;
      }
    }

    .txtTemplateFrame {
      @include breakpoint(md) {
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        padding: 20px;
      }
    }

    .close_bar {
      position: absolute;
      top: 10px;
      width: calc(100% - (2 * 24px));
      background: transparent;
      z-index: 1000;
      justify-content: center;
      align-items: center;
      display: flex;
      @include breakpoint(md) {
        top: 27px;
        right: 18px;
        width: 20px;
        justify-content: inherit;
      }

      .close_button {
        padding: unset;
        cursor: pointer;
        font-size: 0.86em;
        background: transparent;
        border: none;
        display: none;
        @include breakpoint(md) {
          display: block;
        }
      }

      .close_notch {
        margin: auto;
        background: #5E5E5E;
        border-radius: 100px;
        width: 50px;
        height: 3px;
        position: relative;
        border: none;
        @include breakpoint(md) {
          display: none;
        }
      }
    }

    .title {
      // font-family: "noto-sans";
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      height: 29px;
      min-width: 344px;
      width: 100%;
      align-items: center;
      display: inline-flex;

      .filter_close {
        align-items: center;
        display: inline-flex;
        float: right;
        margin-left: auto;
        cursor: pointer;
        @include breakpoint(md) {
          display: none;
        }
      }
    }

    .non_arrowback_container {
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      height: 29px;
      margin-bottom: 4px;
      align-items: center;
      display: inline-flex;
    }

    .arrowback_container {
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      height: 29px;
      margin-bottom: 4px;
      align-items: center;
      display: inline-flex;
      cursor: pointer;
    }

    .withLine {
      height: 48px !important;

      &::after {
        content: " ";
        border: 1px solid #e4e4e4;
        border-width: 1px 0px 0px 0px;
        position: absolute;
        top: 70px;
        left: 0px;
        width: 100%;
      }
    }

    .content {
      max-height: calc(80vh - 150px);
      overflow-y: auto;
      margin-bottom: 50px;
      padding-top: 14px;
      padding-bottom: 10px;
      @include breakpoint(md) {
        margin-bottom: 70px;
      }
    }

    .buttonGroup {
      height: 96px;
      width: calc(100% - (2 * 16px));
      position: absolute;
      bottom: 0px;
      left: 16px;
      padding: 24px 0px;
      display: flex;
      &.desktopHide {
        @include breakpoint(md) {
          display: none;
        }
      }

      button {
        border-radius: 24px;
        border: 2px solid #2c2c2c;
        margin: 0px 8px;
        width: 100%;
        //font-family: "noto-sans";
        font-family: inherit;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        background-color: #fff;
        cursor: pointer;

        &.btnOK {
          background: #2c2c2c;
          color: #fff;
        }

        &.btnCancel {
          color: #2c2c2c;
        }

        &.btnReset {
          color: #2c2c2c;
        }
      }
    }
  }

  .confirm_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: $color_gray_dark;
    opacity: 0.7;
    align-items: center;
    z-index: 999;
  }
}
